<script setup lang="ts">
import { parseJwt } from '~/utils/jwt'
import type { JsonLdCollection, Rental } from '~/types/api'
import { InternalRouteName } from '~/constants/internal-route-name'

const { t } = useI18n()
const { $apiFetch } = useNuxtApp()
const session = useSessionCookie()
const route = useRoute()
const currentUser = useCurrentUserState()

interface SessionData {
    pendingRentals: Array<Rental>
}

const { data } = await useAsyncData<SessionData>('session', async () => {
    try {
        if (!currentUser.value) {
            return {
                pendingRentals: [],
            } as SessionData
        }

        return {
            pendingRentals:
                (
                    await $apiFetch<JsonLdCollection<Rental>>('/rentals', {
                        method: 'GET',
                        query: {
                            'properties[1]': 'id',
                            'status[0]': 'created',
                            'status[1]': 'waiting_for_payment',
                            'departure[strictly_after]': 'now + 23 hours',
                        },
                    })
                )?.['hydra:member'] || ([] as Array<Rental>),
        } as SessionData
    }
    catch (error) {
        console.warn('error during auth', error)

        return {
            pendingRentals: [],
        } as SessionData
    }
})

const jwt = computed(() => {
    return parseJwt(session.value?.token)
})

const pendingRentals = computed<Array<Rental>>(() => {
    return data.value?.pendingRentals || []
})

const pendingRentalsLabel = computed(() => {
    return t('count_pending_rentals', { count: pendingRentals.value.length })
})

const isLogged = computed(() => {
    return currentUser.value && session.value?.token && jwt.value && jwt.value.exp > Date.now() / 1000
})

const fullName = computed(() => {
    const initials = currentUser.value?.firstName?.charAt(0)
    return initials && currentUser.value?.lastName ? `${initials}. ${currentUser.value.lastName}` : undefined
})
</script>

<template>
    <nav :class="$style.root">
        <VMenuLink
            :class="$style['pro-link']"
            :route="{ name: InternalRouteName.PRO_INDEX }"
            class="text-body-m medium"
        >
            {{ $t('pages.pro.index.title') }}
        </VMenuLink>
        <template v-if="isLogged">
            <VButton
                v-if="currentUser"
                size="m"
                outlined
                :to="{ name: InternalRouteName.USER_RENTALS }"
            >
                {{
                    fullName
                }}
            </VButton>

            <div v-if="pendingRentals.length > 0">
                <VButton
                    v-if="pendingRentals.length > 1"
                    size="m"
                    :to="{ name: InternalRouteName.USER_RENTALS }"
                >
                    {{ pendingRentalsLabel }}
                </VButton>
                <VButton
                    v-else
                    size="m"
                    :to="{ name: InternalRouteName.RESERVATION_CONFIRMATION, params: { id: pendingRentals[0].id } }"
                >
                    {{ pendingRentalsLabel }}
                </VButton>
            </div>
        </template>
        <VButton
            v-else
            size="m"
            outlined
            :to="{ name: InternalRouteName.LOGIN, query: { redirect: route.path } }"
        >
            <template #icon>
                <SvgIcon
                    name="account"
                    view-box="0 0 24 24"
                    width="24"
                    height="24"
                />
            </template>
            {{ t('login') }}
        </VButton>
    </nav>
</template>

<style lang="scss" module>
.root {
    display: flex;
    align-items: center;
    gap: var(--spacing-2-xs, 16px);

    @include media('>=vl') {
        gap: 24px;
    }
}

.pro-link {
    white-space: nowrap;

    @include media('<vl') {
        @include text-menu-xl;
    }

    @include media('>=vl') {
        height: 100%;
    }
}
</style>
